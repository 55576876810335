<template>

  <div class="box">
    <h3 class="subtitle is-size-5 mb-0">
      Conference Mode
    </h3>
    <p class="mb-3">
      Copy the conference link. People with the open ended link will be able to request swag, you can approve or reject the request.<br />
    </p>
    <div class="field has-addons">
      <div class="control" style="width: 100%;">
        <input class="input" type="text" style="background-color: white;" v-model="conferenceInviteUrl" ref="conferenceInvite">
      </div>
      <div class="control">
        <a class="button is-black" :disabled="isCopied" @click="copyURL()" style="width: 90px;">
          {{ isCopied ? 'Copied' : 'Copy' }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: ['campaignId'],
  setup(props) {
    const isCopied = ref(null)
    const conferenceInvite = ref(null)

    const conferenceInviteUrl = `${window.location.origin}/invite/${props.campaignId}`

    const copyURL = () => {
      conferenceInvite.value.select()
      document.execCommand('copy')
      isCopied.value = true

      setTimeout(() => isCopied.value = null, 3000)
    }

    return {
      conferenceInvite,
      isCopied,
      conferenceInviteUrl,
      copyURL
    }
  }
}
</script>
