<template>
  <div v-if="campaign && invites">
    <nav class="navbar top" role="navigation" aria-label="main navigation">
      <div class="navbar-brand is-flex-grow-1 is-justify-content-space-between">
        <div class="navbar-item pl-0">
          
          <Icon icon="chevron-left" @click="$router.back()" />

          <div class="ml-3">
            <p class="heading">
              <span v-if="!campaign.submittedAt" class="has-text-weight-normal">
                Expires {{ formatDate(campaign.expiryDate) }}
              </span>
              <span v-if="campaign.submittedAt" class="has-text-weight-normal">
                Submitted {{ formatDate(campaign.submittedAt) }}
              </span>
            </p>
            <h1 class="title mb-0" style="line-height: 1;">
              {{ campaign.campaignName }}
            </h1>
          </div>
        </div>
        <div class="navbar-item is-hidden-mobile">
          <button
            v-if="!campaign.submittedAt && invites.length"
            class="button is-black ml-auto"
            @click="scrollToBottom()"
          >
            Finish Campaign
          </button>
          <a
            v-if="campaign.invoice_url"
            target="_blank"
            :href="campaign.invoice_url"
            class="button is-black"
          >
            View Invoice
          </a>
        </div>
      </div>
    </nav>

    <section v-if="campaign.completedAt" class="section has-border">
      <div class="box">
        <h3 class="subtitle is-size-5 mb-0">
          Order shipped
        </h3>
        <p>
          Please check back on this page to check on the shipment status of your orders. We will notifiy you when all products have been delivered.
        </p>
        <p>TODO copy?</p>
      </div>
    </section>
    <section v-if="campaign.mode === 'invite' && !invites.length" class="section has-border">
      <div class="notification is-danger is-light mb-0">
        Campaign has been cancelled
      </div>
    </section>

    <section v-if="campaign && campaign.submittedAt" class="section has-border">
      <h1 class="title mb-3">
        Thank you for your order
      </h1>

      <p>You will receive a notification once your packages have shipped.</p>

      <!-- <label class="label">Campaign order details</label> -->
      <hr />

      <div class="columns is-mobile is-multiline">        
        <!-- <div class="column is-6">
          <label class="label has-text-weight-bold">Order total</label>
          <p>{{ formatCurrency(campaign.charge * .01, 'cad') }}</p>
        </div> -->
        <div class="column is-6">
          <label class="label has-text-weight-bold">Date submitted</label>
          <p>{{ formatDate(campaign.submittedAt) }}</p>
        </div>
        <div class="column is-6">
          <label class="label has-text-weight-bold">Payment method</label>
          <Card :card="paymentMethods.find(x => x.id === campaign.paymentMethod).card" />
        </div>
      </div>
    </section>

    <section v-if="!campaign.submittedAt && campaign.mode === 'invite' && invites.length" class="section has-border">

      <h3 class="subtitle is-size-3">
        Recipients
      </h3>
      <AddRecipientModal
        v-if="!campaign.submittedAt && campaign.mode === 'invite' && !invites.length"
        :campaign="campaign"
        :invites="invites"
        :campaignViewMode="`view`"
      />
      
      <div class="table-container">
        <table class="table is-fullwidth is-striped">
          <thead v-if="unansweredInvites.length" class="has-background-warning">
            <tr>
              <th colspan="4">Sent</th>
            </tr>
          </thead>
          <tbody>
            <InviteRow
              v-for="invite in unansweredInvites"
              :key="invite.id"
              :invite="invite"
              :rootInviteList="invites"
            />
          </tbody>
          <thead v-if="answeredInvites.length" class="has-background-success">
            <tr>
              <th colspan="4">Responded</th>
            </tr>
          </thead>
          <tbody>
            <InviteRow
              v-for="invite in answeredInvites"
              :key="invite.id"
              :invite="invite"
              :rootInviteList="invites"
            />
          </tbody>
          <thead v-if="declinedInvites.length" class="has-background-light">
            <tr>
              <th colspan="4">Declined</th>
            </tr>
          </thead>
          <tbody>
            <InviteRow
              v-for="invite in declinedInvites"
              :key="invite.id"
              :invite="invite"
              :rootInviteList="invites"
            />
          </tbody>
        </table>
      </div>
    </section>

    <section v-if="!campaign.submittedAt && campaign.mode === 'conference'" class="section has-border">
      <h3 class="subtitle is-size-3">
        Recipients
      </h3>

      <ConferenceLink :campaignId="campaignId" />

      <div class="table-container">
        <table class="table is-fullwidth">
          <thead v-if="answeredInvites.length" class="has-background-success">
            <tr>
              <th colspan="4">Approved Requests</th>
            </tr>
          </thead>
          <tbody v-if="answeredInvites.length">
            <InviteRow
              v-for="invite in answeredInvites"
              :key="invite.id"
              :rootInviteList="invites"
            />
          </tbody>
          <thead v-if="requestedInvites.length" class="has-background-warning">
            <tr>
              <th colspan="4">Pending Requests</th>
            </tr>
          </thead>
          <tbody>
            <InviteRow
              v-for="invite in requestedInvites"
              :key="invite.id"
              :rootInviteList="invites"
            />
          </tbody>
          <thead v-if="rejectedInvites.length" class="has-background-danger">
            <tr>
              <th colspan="4">Rejected Requests</th>
            </tr>
          </thead>
          <tbody v-if="rejectedInvites.length">
            <InviteRow
              v-for="invite in rejectedInvites"
              :key="invite.id"
              :rootInviteList="invites"
            />
          </tbody>
        </table>
      </div>
    </section>

    <section v-if="campaign.submittedAt" class="section has-border">

      <h3 class="subtitle is-size-3">
        Recipients
      </h3>

      <div class="table-container">
        <table class="table is-fullwidth">
          <thead v-if="unshippedInvites.length" class="has-background-light">
            <tr>
              <th colspan="4">In Progress</th>
            </tr>
          </thead>
          <tbody>
            <InviteRow
              v-for="invite in unshippedInvites"
              :key="invite.id"
              :invite="invite"
              :rootInviteList="invites"
            />
          </tbody>
          <thead v-if="shippedInvites.length" class="has-background-light">
            <tr>
              <th colspan="4">Shipped</th>
            </tr>
          </thead>
          <tbody>
            <InviteRow
              v-for="invite in shippedInvites"
              :key="invite.id"
              :invite="invite"
              :rootInviteList="invites"
            />
          </tbody>
        </table>
      </div>
    </section>

    <section class="section has-border">
      <h3 class="subtitle is-size-3">
        Campaign Products
      </h3>
      <div class="columns is-multiline is-mobile">
        <div v-for="product in campaign.products" :key="product.id" class="column is-3-desktop is-4-tablet is-6-mobile">
          <div class="product has-text-centered">
            <figure v-if="product.images[0]" class="image mx-auto mb-3">
              <img :src="product.images[0].url">
            </figure>
            <p class="heading mt-auto">{{ product.line1 }}</p>
            <h4 class="subtitle mb-0">{{ product.line2 }}</h4>
            <p class="price" v-html="formatCurrency(product.charge, 'CAD')" />
          </div> 
        </div>
      </div>
    </section>

    <section class="section has-border">
      <h3 class="subtitle is-size-3">
        Accepted Gifts
      </h3>

      <div class="table-container">
        <table class="table is-fullwidth">
          <thead class="has-background-light is-hidden-mobile">
            <th>Item</th>
            <th>Color(s)</th>
            <th>Personalization</th>
            <th class="has-text-right">Orders to date</th>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.name">
              <td>
                <article class="media mt-3">
                  <figure v-if="item.image" class="media-left">
                    <p class="image is-128x128">
                      <img :src="item.image.url">
                    </p>
                  </figure>
                  <div class="media-content has-text-weight-normal mt-3">
                    <p class="heading">{{ item.line1 }}</p>
                    <h3 class="subtitle mb-0">{{ item.name }}</h3>
                    <p class="price">{{ formatCurrency(item.charge, 'cad') }}</p>

                    <div class="is-hidden-tablet">
                      <div class="buttons mb-0">
                        <button
                          v-for="color in item.chosenColors"
                          :key="color"
                          :class="`button is-rounded `"
                          class="button is-color is-rounded"
                          :style="`--value: ${color}; pointer-events: none;`"
                        />
                      </div>
                      <p v-if="item.logo" class="help" style="max-width: 140px; overflow: hidden; text-overflow: ellipsis;">
                        File: <a :href="item.logo.downloadUrl" target="_blank" class="has-text-info">{{ item.logo.name }}</a>
                      </p>
                      <p class="help">
                        Quantity: {{ item.quantity }}
                      </p>
                    </div>
                  </div>
                </article>
              </td>
              <td class="is-hidden-mobile">
                <div class="buttons">
                  <button
                    v-for="color in item.chosenColors"
                    :key="color"
                    :class="`button is-rounded `"
                    class="button is-color is-rounded"
                    :style="`--value: ${color}; pointer-events: none;`"
                  />
                </div>
              </td>
              <td class="is-hidden-mobile" style="max-width: 140px; overflow: hidden; text-overflow: ellipsis;">
                <template v-if="item.logo">
                  File: <a :href="item.logo.downloadUrl" target="_blank" class="has-text-info">{{ item.logo.name }}</a>
                </template>
              </td>
              <td class="has-text-right is-hidden-mobile">
                {{ item.quantity }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="!Object.keys(items).length ">
            <tr>
              <td class="has-text-centered p-6" colspan="4">
                {{ campaign.mode === 'conference' ? 'No recipient responses yet' : 'No approved requests yet' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <PaymentSummary
      v-if="answeredInvites.length"
      :orders="answeredInvites"
      :authorized="campaign.authorized"
      @emitShippingAndFees="shippingAndFees = $event"
    >
      <template v-slot:items>
        <div class="field">
          <h3 class="subtitle is-size-6">
            Items
          </h3>

          <div v-for="item in items" :key="item.name" class="level is-mobile mb-4">
            <button class="button is-small is-rounded mr-3" style="pointer-events: none;">
              {{ item.quantity }}
            </button>
            <span class="mr-auto">{{ item.name }}</span>
            <span v-html="formatCurrency(item.charge * item.quantity, 'CAD')" />
          </div>
        </div>
      </template>
    </PaymentSummary>

    <section v-if="!answeredInvites.length" class="section">
      <h3 class="subtitle is-size-3">
        Payment
      </h3>
      
      <p class="has-text-weight-bold has-text-centered my-5">
        {{ campaign.mode === 'conference' ? 'No recipient responses yet' : 'No approved requests yet' }}
      </p>
    </section>

    <div v-if="answeredInvites.length" class="button-footer has-menu">
      <router-link v-if="campaign.submittedAt" :to="{ name: 'Dashboard' }" class="button is-black">
        Back to Dashboard
      </router-link>

      <button
        v-if="answeredInvites.length && !campaign.submittedAt"
        class="button is-black px-6"
        :disabled="!answeredInvites.length"
        @click="showConfirmSubmitModal = true"
      >
        Submit Campaign
      </button>
    </div>
  


    <Modal
      v-if="showConfirmSubmitModal"
      title="Confirm Order Submission"
      :isSmall="true"
      @close="showConfirmSubmitModal = false"
    > 
      <p v-if="campaign.mode === 'conference'">
        Submitting the order will close the campaign, and recipients will no longer be able to select gifts. Would you like to proceed?
      </p>

      <p v-else-if="unansweredInvites.length">
        Submitting the order will close the campaign, and the remaining {{ unansweredInvites.length }} recipients will no longer be able to select gifts. Would you like to proceed?
      </p>

      <p v-else>
        Are you sure you want to submit this order? Doing so will close the campaign.
      </p>

      <template v-slot:footer>
        <button class="button is-black" @click="submitCampaign()">
          Submit Campaign
        </button>
        <button class="button" @click="showConfirmSubmitModal = false">
          Cancel
        </button>
      </template>
    </Modal>

    <Modal
      v-if="showSuccessModal"
      title="Payment Confirmed!"
      :isSmall="true"
      @close="showSuccessModal = false"
    > 
      <template v-slot:footer>
        <router-link v-if="campaign.submittedAt" :to="{ name: 'Dashboard' }" class="button is-black">
          Back to Dashboard
        </router-link>
      </template>
    </Modal>

  </div>
</template>

<script>
import { inject, ref, computed } from 'vue'
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'
import getCollection from '@/composables/getCollection'
import useDocument from '@/composables/useDocument'
import useCollection from '@/composables/useCollection'
import Icon from '@/components/Icon.vue'
import PanelTabs from '@/components/Campaign/PanelTabs.vue'
import AddRecipientModal from '@/components/Modals/AddRecipientModal.vue'
import ConferenceLink from '@/components/Campaign/ConferenceLink.vue'
import InviteRow from '@/components/Campaign/InviteRow.vue'
import PaymentSummary from '@/components/Campaign/PaymentSummary.vue'
import Modal from '@/components/Modals/Modal.vue'
import Card from '@/components/Payments/Card.vue'
import { timestamp } from '@/firebase/config'
import { generateCampaignRecipientsHTML, generateCampaignItemsHTML } from '@/utils/utils'
import filters from '@/utils/filters'
import {adminCampaignReadyEmail} from '@/utils/mailHandler.js'

export default {
  props: ['campaignId'],
  components: { Icon, PanelTabs, AddRecipientModal, ConferenceLink, InviteRow, PaymentSummary, Modal, Card },
  setup(props) {
    const { user } = getUser()
    const { document: campaign } = getDocument('campaigns', props.campaignId)    
    const { documents: invites } = getCollection('invites', ['campaignId', '==', props.campaignId])
    
    const campaignViewMode = 'view'

    const now = timestamp()
    const shippingAndFees = ref()
    const showConfirmSubmitModal = ref(false)
    const isPending = inject('isPending')
    const paymentMethods = inject('paymentMethods')

    const unansweredInvites = computed(() => {
      return invites.value && invites.value.filter(invite => !invite.acceptedAt && !invite.declinedAt)
    })

    const answeredInvites = computed(() => {
      return invites.value && invites.value.filter(invite => invite.acceptedAt)
    })

    const declinedInvites = computed(() => {
      return invites.value && invites.value.filter(invite => invite.declinedAt)
    })

    const requestedInvites = computed(() => {
      return invites.value && invites.value.filter(invite => invite.request && !invite.acceptedAt && !invite.rejectedAt)
    })

    const rejectedInvites = computed(() => {
      return invites.value && invites.value.filter(invite => invite.request && invite.rejectedAt)
    })

    const unshippedInvites = computed(() => {
      return invites.value && invites.value.filter(invite => invite.acceptedAt && !invite.shippedAt)
    })
    
    const shippedInvites = computed(() => {
      return invites.value && invites.value.filter(invite => invite.shippedAt)
    })

    const items = computed(() => {
      if (!answeredInvites.value) return
      let orders = {}
      answeredInvites.value.forEach(order => {
        order.chosenProducts.forEach(product => {
          if (!orders.hasOwnProperty(product.id)) {
            orders[product.id] = {
              image: product.images[0],
              line1: product.line1,
              name: product.line2,
              quantity: 1,
              chosenColors: [product.chosenColor],
              logo: product.logo,
              charge: product.charge
            }
          } else {
            orders[product.id].quantity ++

            if (!orders[product.id].chosenColors.includes(product.chosenColor)) {
              orders[product.id].chosenColors.push(product.chosenColor)
            }
          }
        })
      })
      return orders
    })

    const showSuccessModal = ref(false)

    const submitCampaign = async () => {
      isPending.value = true
      const includeTax = process.env.VUE_APP_INCLUDE_TAX
      const charge = includeTax ? shippingAndFees.value.grandTotal * 1.13 : shippingAndFees.value.grandTotal

      const { updateDoc: updateCampaign } = useDocument('campaigns', props.campaignId)      
      await updateCampaign({
        submittedAt: timestamp(),
        charge: Math.round(charge * 100),
        paymentMethod: campaign.value.paymentMethod,
      })
      
      const promises = unansweredInvites.value.map(async invite => {
        const { updateDoc: updateInvite } = useDocument('invites', invite.id)
        await updateInvite({
          expiryDate: timestamp()
        })
      })
      await Promise.all(promises)

      let productsArr = []
      answeredInvites.value.forEach(invite => invite.chosenProducts.forEach(product => productsArr.push(product)))

      // console.log('productsArr', productsArr)

      // Found a bug, reverted.
      adminCampaignReadyEmail (user, props, campaign, productsArr)

      // Moved to mailHAndler.js
      // const { addDoc: addEmail } = useCollection('mail')
      // await addEmail({
      //   to: 'support@swagible.com',
      //   templateId: 'd-3f1eab6212b74ed9af79e9b017c30d2b', // SWAGIBLE:ADMINCAMPREADY
      //   dynamic_template_data: {
      //     accountholder_first_name: user.value.displayName,
      //     accountholder_last_name: user.value.displayName,
      //     accountholder_email: user.value.email,
      //     accountholder_company: campaign.value.accountCompany,
      //     campaign_name: campaign.value.campaignName,
      //     campaign_expiry: filters.formatDate(campaign.value.expiryDate),
      //     campaign_recipientsHTML: generateCampaignRecipientsHTML(campaign.value),
      //     campaign_itemsHTML: generateCampaignItemsHTML(productsArr),
      //     dashboard_url: `${process.env.VUE_APP_BASE_URL}/dashboard`
      //   }
      // })
      isPending.value = false
      showConfirmSubmitModal.value = false
      showSuccessModal.value = true
    }

    const scrollToBottom = () => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }

    return {
      user,
      campaign,
      now,

      invites,
      unansweredInvites,
      answeredInvites,
      requestedInvites,
      rejectedInvites,
      declinedInvites,
      unshippedInvites,
      shippedInvites,
      
      items,
      
      shippingAndFees,

      scrollToBottom,
      showConfirmSubmitModal,
      submitCampaign,
      showSuccessModal,
      paymentMethods,

      ...filters
    }
  }
}
</script>