<template>
  <!-- Create Campaign Recipients-->
  <AddRecipients
    v-if="campaignViewMode == 'create'"
    ref="recipientsComponent"
    :recipients="newRecipients"
    :existingRecipients="campaign.clients"
    :campaignViewMode="`create`"
  >
    <button
      class="button is-black is-hidden-tablet"
      :disabled="!newRecipients.length"
      @click="calcNewCharge()"
    >
      Calculate New Estimate
    </button>
  </AddRecipients>


  <button v-if="campaignViewMode == `create`"
    class="button is-black is-hidden-mobile mb-5"
    :disabled="!newRecipients.length"
    @click="calcNewCharge()"
  >
    Calculate New Estimate
  </button>

  <!-- View Campaign Recipients-->
  <AddRecipients
  v-if="campaignViewMode == 'view'"
  ref="recipientsComponent"
  :recipients="newRecipients"
  :existingRecipients="campaign.clients"
  :campaignViewMode="`view`"
>
  <button
    class="button is-black"
    :disabled="!newRecipients.length"
    @click="calcNewCharge()"
  >
    Calculate New Estimate
  </button>
</AddRecipients>



  <Modal
    v-if="showAuthorizedModal"
    :isSmall="true"
    @close="showAuthorizedModal = false"
  >
    <div class="has-text-centered">
      <p class="heading">New Amount</p>
      <PaymentInfo
        :campaign="campaign"
        @emitAuthorized="newAuthorized = $event"
      />
    </div>
    <template v-slot:footer>
      <button
        :class="`button is-black ${isPending && 'is-loading'}`"
        v-html="`Authorize ${formatCurrency(newAuthorized, 'CAD')}`"
        @click="handleSubmit()"
      />
      <button class="button" @click="showAuthorizedModal = false">
        Cancel
      </button>
    </template>
  </Modal>
</template>

<script>
import { ref, inject } from 'vue'
import getUser from '@/composables/getUser'
import useCollection from '@/composables/useCollection'
import useDocument from '@/composables/useDocument'
import Modal from '@/components/Modals/Modal.vue'
import AddRecipients from '@/components/CreateCampaign/AddRecipients.vue'
import PaymentInfo from '@/components/CreateCampaign/PaymentInfo.vue'
import { timestamp } from '@/firebase/config'
import filters from '@/utils/filters'
import { formatDateAsMMMDD } from '@/utils/utils'
import {recipientInviteEmail} from '@/utils/mailHandler'

export default {
  props: ['campaign', 'invites', 'campaignViewMode'],
  emits:['close'],
  components: { Modal, AddRecipients, PaymentInfo },
  setup(props, { emit }) {
    const { user } = getUser()

    const recipientsComponent = ref(null)
    const newRecipients = ref([])

    const showAuthorizedModal = ref(false)
    const newAuthorized = ref('')

    const calcNewCharge = () => {
      recipientsComponent.value.addRecipient()
      props.campaign.clients = [...props.campaign.clients, ...newRecipients.value]
      showAuthorizedModal.value = true
    }

    const isPending = inject('isPending')

    const handleSubmit = async () => {
      isPending.value = true

      const { updateDoc: updateCampaign } = useDocument('campaigns', props.campaign.id)
      await updateCampaign({
        clients: props.campaign.clients,
        authorized: filters.formatAmountForStripe(newAuthorized.value, 'cad')
      })

      const { addDoc: addInvite } = useCollection('invites')
      const { addDoc: addEmail } = useCollection('mail')

      const { id, clients, ...campaignData } = props.campaign
      for (const client of newRecipients.value) {
        console.log('client', client)

        const invite = await addInvite({
          ...client,
          ...campaignData,
          campaignId: props.campaign.id,
          created: timestamp()
        })

        await recipientInviteEmail(client, user, invite, props, campaign);
        
        // Moved to utils.
        // await addEmail({
        //   to: client.email,
        //   templateId: 'd-9ffec509f4c448f4beaa61dfefcc56ff', // SWAGIBLE:RECIPIENTINVITE
        //   dynamic_template_data: {
        //     recipient_first_name: client.name,
        //     accountHolder_first_name: user.value.displayName,
        //     campaignExpiryDate: formatDateAsMMMDD(props.campaign.expiryDate.seconds),
        //     first_name: user.value.displayName, //accountHolder_first_name again :/
        //     swag_url: `${process.env.VUE_APP_BASE_URL}/invite/${invite.campaignId}/${invite.id}`,
        //     decline_swag_url: `${process.env.VUE_APP_BASE_URL}/invite/${invite.campaignId}/${invite.id}?showDecline=true`
        //   }
        // })
      }
      isPending.value = false
      newRecipients.value = []

      showAuthorizedModal.value = false
    }

    return {
      recipientsComponent,
      newRecipients,
      
      calcNewCharge,
      showAuthorizedModal,
      newAuthorized,

      isPending,
      handleSubmit,
      ...filters,
    }
  }
}
</script>